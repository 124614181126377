@charset 'UTF-8';

// ========================================
// サイトの基本設定
// ========================================

// 変数設定
// ----------------------------------------

// カラー
/* 標準色 */
$black:      #242424;
/* ベースカラー */
$main:      #239bd7;
$key:       #898076;
$em:        #bf313e;
$link:      #e57e29;
// $link:      #068ac5;
/* 補助色 */
$aux:       #767676;
/* 線など */
$pale:      #dedede;
/* 薄い背景 */
$thin:      #f9f5f2;
$kthin:     #e0d4c4;
$gray:      #F5F5F5;
/* オリジナル */


// フォント
$font: 'Noto Sans CJK JP', 'Noto Sans JP', meiryo, "メイリオ" sans-serif;
$font-min: 'Noto Serif JP','ＭＳ Ｐ明朝','MS PMincho', serif;
$font-en: 'Libre+Baskerville', serif;
$font-load: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴシック Pro", "hiragino kaku gothic pro", meiryo, "メイリオ", sans-serif;

// メディアクエリ
$breakpoints: (
    xs: 'only screen and (max-width: 479.98px)',
    sm: 'only screen and (min-width: 480px)',
    md: 'only screen and (min-width: 768px)',
    lg: 'only screen and (min-width: 1000px)',
    xl: 'only screen and (min-width: 1280px)',
    h: 'only screen and (min-height: 940px)',
);

$easeOutCubic: 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
$easeInOutCubic: 0.8s cubic-bezier(0.65, 0.05, 0.36, 1);



// 基本設定
// ----------------------------------------
*,
*::before,
*::after {
    box-sizing: border-box;
}
html {
    width: 100%;
    height: 100%;
}
.wf-active body {
    font-family: $font-min;
}
body {
    font-size: calc(14px + 2*(100vw - 320px)/1280);
    font-family: $font-load;
    line-height: 1.8;
    font-weight: 400;
    width: 100%;
    height: 100%;
}
/*ie7*/
*:first-child+html body { font-size:75%; }
/*ie5-6*/
* html body { font-size:75%; }

// タッチデバイスの反応向上 reboot.cssより
// ----------------------------------------
a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
-ms-touch-action: manipulation;
touch-action: manipulation; }

// 画像設定
// ----------------------------------------
img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
a:hover img {
    opacity: .8;
}

figure {
    text-align: center;
}
figure {
    figcaption {
        font-size: calc(12px + 2*(100vw - 320px)/1280);
        color: $aux;
        text-align: center;
        margin: 5px 0 0;
    }
}



// カラー設定
// ----------------------------------------

a {
    color: $link;
    text-decoration: none;
    backface-visibility: hidden;
    transition: color .3s;
    &:hover {
        color: darken($link, 30%);
    }
}
a.line {
    text-decoration: underline;
    transition: text-decoration .3s;
    &:hover {
        text-decoration: none;
    }
}

// input
// ----------------------------------------
input[type="submit"] {
    background: none;
    border-radius: 0;
    -webkit-box-sizing: content-box;
    -webkit-appearance: button;
    appearance: button;
    border: none;
    box-sizing: border-box;
    font-size: 100%;
    cursor: pointer;
    &::-webkit-search-decoration {
        display: none;
    }
    &::focus {
        outline-offset: -2px;
    }
}
