/*
Version: 1.0
Author:リシカ
Author URI: https://resika.net/
 */
@charset "UTF-8";

// Import reset
@import "_reset.scss";

// Import slick
@import "_slick.scss";
@import "_slick-theme.scss";

// Import lightcase
@import 'components/settings';
@import 'components/fonts/font-lightcase';
@import 'components/mixins/presets';
@import 'components/mixins/utilities';
@import 'components/modules/animations';
@import 'components/modules/case';
@import 'components/modules/content';
@import 'components/modules/error';
@import 'components/modules/global';
@import 'components/modules/info';
@import 'components/modules/loading';
@import 'components/modules/navigation';
@import 'components/modules/overlay';

// Import ori modules
@import "_mixin.scss";
@import "_setting.scss";
@import "_module.scss";


#container {
    position: relative;
}

// ----------------------------------------
// ヘッダー
// ----------------------------------------
#header {
    z-index: 10000;
    transition: .3s;
    width: 100%;
}


.wf-active {
    .header-top--language {
        .lang a {
            font-family: $font-en;
        }
    }
}
.header-top {
    padding: 5px calc(10px + 20*(100vw - 320px)/1280) 8px 0;
    background-color: #e0d4c4;
    line-height: 1.0;
    font-size: 0.75rem;
    color: white;
    &--language {
        line-height: 1.0;
        text-align: right;
        .lang {
            line-height: 1.0;
            a {
                color: #008080;
                font-family: $font-load;
                display: inline-block;
                font-size: 0.75rem;
                line-height: 1.0;
                margin: 0 5px 0;
                transition: color .3s;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}


.wf-active {
    .site-nav {
        &--list {
            li {
                a {
                    span {
                        font-family: $font-en;
                    }
                }
            }
        }
    }
}
.site-nav {
    background-color: rgba( 255, 255, 255, 1);
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $kthin;
    &--list {
        display: none;
        @include media(md) {
            display: flex;
        }
        li {
            font-size: 0.875rem;
            border-left: 1px solid $kthin;
            line-height: 1.3;
            a {
                padding: 30px 10px;
                color: $black;
                position: relative;
                z-index: 0;
                font-weight: lighter;
                display: block;
                text-align: center;
                white-space: nowrap;
                @include media(lg) {
                    min-width: 110px;
                }
                &:hover {
                    &:before {
                        opacity: 0;
                    }
                }
/*
                &.specialist {
                    &:before {
                        background: linear-gradient(white 40%, $kthin 100%);
                    }
                    &:after {
                        background: white;
                    }
                }
*/
                &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    left: 0;
                    top: 0;
                    background: white;
                    transition: opacity 1s ease-out;
                }
                &:after {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    z-index: -2;
                    left: 0;
                    top: 0;
                    background: linear-gradient(white 40%, $kthin 100%);
                }
                span {
                    display: block;
                    font-size: .5rem;
                    line-height: 1.0;
                    font-family: $font-load;
                    margin: 5px 0 0;
                    white-space: nowrap;
                }
            }
        }
    }
    &--logo {
        line-height: 1.0;
        padding: 10px 20px;
        &:hover {
            img {
                opacity: 1;
            }
        }
        img {
            height: auto;
            width: 210px;
            @include media(sm) {
                width: 340px;
            }
            @include media(md) {
                width: inherit;
            }
        }
    }
}





// ----------------------------------------
// ぱんくず
// ----------------------------------------
.wf-active {
    .breadcrumb {
        &--item {
            &:before,
            a {
                font-family: $font-en;
            }
        }
    }
}
.breadcrumb {
    width: 100%;
    display: block;
    padding: 3px 20px 5px;
    background-color: $thin;
    .front & {
        display: none;
    }
    &--list {
        font-size: 0;
        margin: 0;
        padding: 0;
    }
    &--item {
        display: inline;
        vertical-align: middle;
        font-size: 0.75rem;
        line-height: 1.2;
        &:first-child:before {
            content: none;
        }
        &:before {
            content: '>';
            display: inline-block;
            color: $aux;
            margin: 0 5px;
            font-family: $font-load;
        }
        a {
            color: $aux;
            transition: color .3s ease-in-out;
            font-family: $font-load;
            &:hover {
                color: $link;
            }
        }
        span {
            color: $aux;
        }
    }
}

// ----------------------------------------
// メイン
// ----------------------------------------
#sec-hero {
    overflow: hidden;
    #slideshow {
        img {
            width: 100%;
            height: auto;
        }
/*
        .slide {
            width: 100%;
            height: 160px;
            overflow: hidden;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            @include media(md) {
                background-position: center center;
                height: 400px;
            }
            &.slide1 {
                background-image: url(images/main1.jpg);
            }
            &.slide2 {
                background-image: url(images/main2.jpg);
            }
            &.slide3 {
                background-image: url(images/main3.jpg);
            }
        }
*/
    }
}



.wf-active {
    .sec-mv .block {
        .title,
        .sup {
            font-family: $font-min;
        }
    }
}
.sec-mv {
    position: relative;
    width: 100%;
    height: auto;
    background: center center / cover no-repeat;
    opacity: 0;
    transition: opacity 2s ease-in;
    &:before {
        display: block;
        padding-top: 160px;
        content: '';
        @include media(md) {
            padding-top: 320px;
        }
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        z-index: -1;
    }
    .action & {
        opacity: 1;
        .block {
            .title {
                opacity: 1;
                transform: translate(0, 0);
            }
            .sup {
                opacity: 1;
                transform: translate(0, 0);
            }
        }
    }
    .block {
        position: absolute;
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        line-height: 1.2;
        .title {
            font-family: $font-load;
            font-size: calc(30px + 10*(100vw - 320px)/1280);
            font-weight: normal;
            line-height: 1.2;
            color: white;
            opacity: 0;
            transform: translate(0, 40px);
            transition: opacity 1s ease-out .5s, transform 1s ease-out .5s;
        }
        .sup {
            font-family: $font-load;
            font-size: calc(16px + 6*(100vw - 320px)/1280);
            line-height: 1.2;
            color: white;
            margin: 20px 0 0;
            padding: 20px 0 0;
            position: relative;
            opacity: 0;
            transform: translate(0, 40px);
            transition: opacity 1s ease-out 1s, transform 1s ease-out 1s;
            &::before {
                display: block;
                content: '';
                width: 60px;
                height: 1px;
                background-color: $thin;
                top: 0;
                left: 0;
                left: 50%;
                position: absolute;
                transform: translate(-50%, 0);
            }
        }
    }
    &.block-reverse {
        flex-direction: column;
        .sup {
            margin: 0 0 10px;
        }
    }
}



// top index


.sec-subsidy {
    .inner {
        border: 1px solid $aux;
        padding: 20px;
    }
}

.sec-blog-top {
    padding: calc(80px + 40*(100vw - 320px)/1280) 5%;
}
.info-list {
    border-top: 1px solid $pale;
    .item {
        border-bottom: 1px solid $pale;
    }
    a {
        padding: calc(10px + 10*(100vw - 320px)/1280) 50px calc(10px + 10*(100vw - 320px)/1280) calc(10px + 10*(100vw - 320px)/1280);
        display: block;
        position: relative;
        color: black;
        transition: color .3s, background-color .3s;
        &.box {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
        &:hover {
            background-color: white;
            color: $link;
            img.gray {
                transition: opacity .3s, filter .3s;
            }
            .title,
            .text {
                color: $link;
            }
        }
        .date {
            font-size: .875rem;
            color: $aux;
        }
        .title {
            color: black;
            transition: color .3s;
        }
        .text {
            color: black;
            transition: color .3s;
            margin: 0 0 0 10px;
            @include media(sm) {
                margin: 0 0 0 20px;
            }
        }
        .pic {
            text-align: center;
            width: 60px;
            height: auto;
        }
        svg {
            position: absolute;
            display: block;
            width: 10px;
            height: 16px;
            fill: currentColor;
            top: 50%;
            right: 20px;
            margin-top: -8px;
        }
    }
}
.blog-list {
    border-top: 1px solid $pale;
    .item {
        border-bottom: 1px solid $pale;
    }
    a {
        padding: calc(10px + 10*(100vw - 320px)/1280) 50px calc(10px + 10*(100vw - 320px)/1280) calc(10px + 10*(100vw - 320px)/1280);
        display: block;
        position: relative;
        color: black;
        transition: color .3s, background-color .3s;
        &.box {
            @include media(lg) {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
            }
        }
        &:hover {
            background-color: $thin;
            color: $link;
            img.gray {
                transition: opacity .3s, filter .3s;
            }
            .title,
            .text {
                color: $link;
            }
        }
        .date {
            font-size: .875rem;
            color: $aux;
        }
        .title {
            color: black;
            transition: color .3s;
        }
        .text {
            color: black;
            transition: color .3s;
            @include media(md) {
                margin: 0 0 0 20px;
            }
        }
        .pic {
            text-align: center;
            width: 60px;
            height: auto;
        }
        svg {
            position: absolute;
            display: block;
            width: 10px;
            height: 16px;
            fill: currentColor;
            top: 50%;
            right: 20px;
            margin-top: -8px;
        }
    }
}

.sec-doctor-top {
    background-color: $gray;
}

.sec-treatment-top {
    background-color: $thin;
    padding: calc(80px + 40*(100vw - 320px)/1280) 5%;
}
.sec-treatment {
    .treatment-list {
        .title {
            text-align: center;
            background-color: $thin;
            padding: 10px 0;
        }
    }
}


.sec-link {
    padding: calc(20px + 10*(100vw - 320px)/1280) 5%;
    background-color: $thin;
    .inner {
        max-width: 960px;
        margin-right: auto;
        margin-left: auto;
    }
    .row {
        margin: -30px 0 0 0;
        @include media(sm) {
            display: flex;
            flex-flow: row wrap;
            margin: -30px 0 0 -30px;
        }
    }
    .col {
        padding: 30px 0 0;
        @include media(sm) {
            flex: 0 0 25%;
            max-width: 25%;
            padding: 30px 0 0 30px;
        }
        @include media(md) {
            flex: 0 0 12.5%;
            max-width: 12.5%;
        }
    }
    a {
        color: $black;
        line-height: 1;
        display: block;
        text-align: center;
        &:hover {
            color: $link;
            .icon {
                fill: currentColor;
            }
            .ttl {
                color: $link;
            }
        }
    }
    .icon {
        line-height: 1;
        width: 40px;
        height: 40px;
        transition: fill .3s;
    }
    .ttl {
        font-size: 1rem;
        line-height: 1.3;
        color: $black;
        font-weight: 500;
        margin-top: 10px;
        transition: color .3s;
        svg {
            margin: 0 0 -2px 0;
            width: 10px;
            height: 16px;
            fill: currentColor;
        }
    }
}

.table-recruit {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid $aux;
    tr {
        border-bottom: 1px solid $aux;
    }
    th {
        width: 20%;
        padding: calc(10px + 10*(100vw - 320px)/1280) calc(10px + 10*(100vw - 320px)/1280);
        font-weight: 400;
        border-right: 1px solid $aux;
    }
    td {
        width: 80%;
        padding: calc(10px + 10*(100vw - 320px)/1280) calc(10px + 10*(100vw - 320px)/1280);
    }
}

.sec-post {
    a:hover {
        .title {
            color: $link;
        }
    }
    .title {
        font-size: calc(20px + 10*(100vw - 320px)/1280);
        line-height: 1.6;
        font-weight: 700;
        color: $black;
        transition: color .3s;
    }
    .text {
        margin: 10px 0 0;
    }
    .meta {
        text-align: right;
        margin: 5px 0 0;
        .date {
            margin: 0 10px 0 0;
            font-size: 0.875rem;
        }
        .category {
            font-size: 0.875rem;
            .sep {
                color: $aux;
                margin: 0 5px;
            }
        }
    }
    .more {
        display: inline-block;
        padding: 10px 20px;
        position: relative;
        border-radius: 100px;
        background-color: $link;
        color: white;
        transition: color .3s, background .3s;
        text-align: center;
        line-height: 1.3;
        &:hover {
            background-color: darken($link, 10%);
            color: white;
        }
        svg {
            margin: 0 0 -1px 0;
            width: 10px;
            height: 16px;
            fill: currentColor;
        }
    }
}

.sec-pager {
    .wp-pagenavi {
        text-align: center;
        margin: 40px 0 0;
        a {
            display: inline-block;
            margin: 5px;
            border-radius: 10px;
            border: 1px solid $pale;
            transition: border-color .8s;
            &:hover {
                border-color: $link;
            }
        }
        span {
            display: inline-block;
            margin: 5px;
            border-radius: 10px;
            border: 1px solid $pale;
            color: $aux;
            &.current {
                border-color: $link;
                background-color: $link;
                color: white;
            }
        }
    }
}

.widget_categories {
    .widget_title {
        display: none;
    }
    ul {
        display: block;
        overflow: hidden;
        @include media(sm) {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            margin: 0 -20px;
        }
        li {
            line-height: 1.5;
            @include media(sm) {
                margin: 10px 5px 0;
            }
            & + li {
                margin: 10px 0 0;
                @include media(sm) {
                    margin: 10px 5px 0;
                }
            }
            &.current-cat {
                a {
                    display: block;
                    background-color: white;
                    border-radius: 50px;
                    padding: 10px 20px;
                    color: white;
                    transition: border .3s, color .3s;
                    text-align: center;
                    background-color: $main;
                    border: 1px solid $main;
                }
            }
            a {
                display: block;
                background-color: white;
                border-radius: 50px;
                padding: 10px 20px;
                color: $black;
                transition: background-color .3s, border .3s,color .3s;
                text-align: center;
                border: 1px solid $link;
                &:hover {
                    background-color: $link;
                    border: 1px solid $link;
                    color: white;
                }
            }
        }
    }
}

.sec-postmeta {
    padding: 2px 5%;
    background-color: $thin;
    .inner {
        max-width: 960px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }
    .date {
        font-size: .875rem;
        display: inline-block;
        margin: 0 10px 0 0;
    }
    .category {
        font-size: .875rem;
        display: inline-block;
    }
}

.sec-time {
    .table-simple {
        max-width: 640px;
        margin: 0 auto;
        border-top: 1px solid $black;
        thead {
            background-color: inherit;
        }
        tr {
            border-bottom: 1px solid $black;
        }
        th, td {
            padding: 5px 0;
            font-size: 0.875rem;
            font-weight: 700;
            line-height: 1.3;
            @include media(sm) {
                font-size: 1rem;
            }
            span {
                display: block;
                font-size: 1rem;
                @include media(sm) {
                    display: inline;
                    font-size: 1.25rem;
                }
            }
        }
        td {
            width: 50px;
        }
    }
}

.sec-top-appendix-anchor {
    padding: 10px 0;
    background-color: $thin;
}
.sec-top-appendix {
    padding: 40px 0;
    background-color: $thin;
    .inner {
        background-color: white;
        padding: 40px;
    }
    .title {
        font-size: 1.25rem;
        line-height: 1.5;
    }
}

.sec-top-contents {
    padding: calc(40px + 40*(100vw - 320px)/1280) 0;
    background-color: $thin;
    .box {
        padding: 20px 30px 30px;
        background-color: white;
        box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
    }
    .title-area {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 0 0 10px;
    }
    .pic {
        width: 60px;
        height: auto;
    }
    .title {
        padding: 0 0 0 10px;
        font-size: 1rem;
        line-height: 1.3;
    }
}

.sec-top-movie {
    .title {
        font-size: calc(18px + 4*(100vw - 320px)/1280);
        line-height: 1.3;
        color: $black;
        font-weight: 700;
        margin-top: 20px;
        transition: color .3s;
    }
    .text {
        font-size: calc(14px + 2*(100vw - 320px)/1280);
        text-align: justify;
        margin-top: 10px;
    }
}


.sec-top-sns {
    @include media(md) {
        display: flex;
        flex-flow: row nowrap;
    }
}

.sec-top-info {
    padding: calc(40px + 40*(100vw - 320px)/1280) 5%;
    background-color: $thin;
    @include media(md) {
        width: 50%;
    }
}

.sec-top-blog {
    padding: calc(40px + 40*(100vw - 320px)/1280) 5%;
    @include media(md) {
        width: 50%;
    }
}

.sec-top-dentist {
    padding: calc(40px + 40*(100vw - 320px)/1280) 5%;
    background-color: $thin;
    a {
        &:hover {
            .title {
                color: $link;
            }
        }
    }
    .title {
        color: $black;
        margin: 10px 0 0;
        transition: color .3s;
    }
    .pic {
        overflow: hidden;
        position: relative;
        display: block;
        padding-top: 61.8%;
        content: '';
        border: 1px solid $pale;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
        }
    }
}




.sec-introduction {
    dl {
        @include media(md) {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }
    dt {
        font-size: .875rem;
        color: $aux;
        @include media(md) {
            width: 40%;
            font-size: 1rem;
            color: $black;
        }
        &:not(:first-child) {
            margin-top: 10px;
            @include media(md) {
                margin-top: 0;
            }
        }
    }
    dd {
        @include media(md) {
            width: 60%;
            font-size: 1rem;
            color: $black;
        }
    }
}


.staff-list {
    width: 100%;
    @include media(md) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: stretch;
    }
    .col {
        padding: calc(20px + 20*(100vw - 320px)/1280);
        @include media(md) {
            max-width: 50%;
            flex: 0 0 50%;
        }
    }
    .box {
        background-color: white;
        padding: calc(30px + 10*(100vw - 320px)/1280);
    }
    table {
        th,
        td {
            font-size: .875rem;
        }
    }
    p {
        font-size: .875rem;
    }
}








.sec-patient-flow {
    li {
        position: relative;
        &:not(:last-child) {
            margin-bottom: 40px;
            padding-bottom: 30px;
            @include media(sm) {
                margin-bottom: 80px;
                padding-bottom: 40px;
            }
        }
        .icon {
            color: $key;
            position: absolute;
            bottom: -30px;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
            height: 30px;
            @include media(sm) {
                bottom: -60px;
                width: 100px;
                height: 60px;
            }
            svg {
                width: 50px;
                height: 30px;
                fill: currentColor;
                @include media(sm) {
                    width: 100px;
                    height: 60px;
                }
            }
        }
    }
}

.sec-price-list {
    table {
        th {
            width: 100%;
            @include media(sm) {
                width: 70%;
            }
        }
        td {
            white-space: nowrap;
        }
    }
}


.column-list {
    border-top: 1px solid $pale;
    article {
        border-bottom: 1px solid $pale;
    }
    span.box {
        background-color: $gray;
        &:hover {
            background-color: $gray;
            color: $black;
            .title,
            .detail {
                color: $black;
            }
        }
        svg {
            display: none;
        }
    }
    .box {
        padding: calc(20px + 10*(100vw - 320px)/1280) 50px calc(20px + 10*(100vw - 320px)/1280) 20px;
        display: block;
        position: relative;
        color: $black;
        transition: color .3s, background-color .3s;
        &:hover {
            background-color: $thin;
            color: $link;
            .title,
            .detail {
                color: $link;
            }
        }
        .title {
            color: black;
            transition: color .3s;
            line-height: 1.6;
        }
        .detail {
            color: black;
            transition: color .3s;
            margin-top: 10px;
        }
        svg {
            position: absolute;
            display: block;
            width: 10px;
            height: 16px;
            fill: currentColor;
            top: 50%;
            right: 20px;
            margin-top: -8px;
        }
    }
}


.sec-photo {
    figure {
        margin: 0;
        display: none;
        &:target {
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 9999;
            #overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: rgba(255, 255, 255, 0.7);
                text-decoration: none;
                color: inherit;
            }
            img {
                animation: fadein .3s;
            }
        }
    }
}
@keyframes fadein {
    0% {
        transform: scale(0.2);
        opacity: 0.2;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.sec-method-perio {
    .table-simple {
        th {
            background-color: white;
            text-align: center;
        }
        td {
            text-align: center;
        }
        td:nth-child(2) {
            background-color: lighten($main, 40%);
        }
        td:nth-child(3) {
            background-color: white;
        }
        td:nth-child(4) {
            background-color: lighten($em, 40%);
        }
        td:nth-child(5) {
            background-color: lighten($em, 30%);
        }
        tbody {
            td {
                font-weight: 700;
            }
        }
    }
}

.wf-active {
    .sec-seminar-list {
        .box {
            .title {
                font-family: $font-min;
            }
        }
    }
}
.sec-seminar-list {
    padding: calc(60px + 60*(100vw - 320px)/1280) 5%;
    background-color: $thin;
    .box {
        background-color: white;
        padding: calc(20px + 20*(100vw - 320px)/1280) 5%;
        position: relative;
        &:not(:last-child) {
            margin-bottom: 60px;
        }
        &.end {
            &:before {
                content: '終了しました';
                color: white;
                text-shadow: $aux 0 0 10px;
                z-index: 10;
                padding: 10px 20px;
                border: 5px solid white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: calc(20px + 20*(100vw - 320px)/1280);
                opacity: .8;
                width: 80%;
                text-align: center;
                @include media(sm) {
                    max-width: 50%;
                }
            }
            &:after {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: .5;
                background-color: $aux;
                content: '';
                z-index: 5;
                top: 0;
                left: 0;
            }
        }
        .title {
            font-family: $font-load;
            line-height: 1.5;
            font-size: calc(18px + 12*(100vw - 320px)/1280);
            span {
                display: block;
                font-size: 1rem;
                color: $aux;
                margin: 10px 0 0;
            }
        }
        table {
            width: 100%;
            border-collapse: collapse;
            border-top: 1px solid $pale;
            margin: 20px 0 0;
            @include media(xs) {
                display: block;
            }
        }
        tbody {
            @include media(xs) {
                display: block;
            }
        }
        tr {
            border-bottom: 1px solid $pale;
            @include media(xs) {
                display: block;
            }
        }
        th {
            background-color: $thin;
            padding: 10px 20px;
            font-weight: 400;
            text-align: center;
            vertical-align: middle;
            white-space: nowrap;
            @include media(xs) {
                display: block;
            }
        }
        td {
            padding: 10px 20px;
        }
    }
}


.wf-active {
    .article-comment-list .box .title {
        font-family: $font-min;
    }
}
.article-comment-list {
    border-top: 1px solid $pale;
    .box {
        border-bottom: 1px solid $pale;
        padding: calc(10px + 20*(100vw - 320px)/1280) 0;
        display: block;
        .title {
            font-family: $font-load;
            font-size: calc(20px + 10*(100vw - 320px)/1280);
            a {
                transition: color .3s;
                &:before {
                    display: inline-block;
                    content: url(images/i-pdf.png);
                    width: 24px;
                    height: 24px;
                    margin: 0 10px 0 0;
                }
            }
        }
        .person,
        .date,
        .paper {
            margin: 10px 0 0;
            font-size: .875rem;
            position: relative;
            padding-left: 60px;
            span {
                position: absolute;
                left: 0;
                top: 0;
                display: inline-block;
                padding: 0 10px;
                border-radius: 30px;
                color: white;
                margin: 0 10px 0 0;
                font-size: .875rem;
            }
        }
        .person {
            span {
                background-color: $main;
            }
        }
        .date {
            span {
                background-color: $key;
            }
        }
        .paper {
            font-size: .75rem;
            a {
                color: $aux;
                &:hover {
                    color: $link;
                }
            }
            span {
                background-color: $aux;
            }
        }
    }
}

.wf-active {
    .book-list .box .title {
        font-family: $font-min;
    }
}
.book-list {
    article {
        border-bottom: 1px solid $pale;
    }
    a {
        &:hover {
            background-color: $thin;
            img.gray {
                transition: opacity .3s, filter .3s;
            }
            .title {
                text-decoration: underline;
            }
        }
        .title {
            color: $link;
        }
    }
    .box {
        padding: calc(10px + 10*(100vw - 320px)/1280) calc(10px + 10*(100vw - 320px)/1280);
        display: block;
        transition: background-color .3s;
        position: relative;
        color: black;
        transition: color .3s;
        @include media(md) {
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
        }
        .pic {
            text-align: center;
            max-width: 100px;
            & + .detail {
                margin: 10px 0 0 0;
                @include media(md) {
                    margin: 0 0 0 20px;
                }
            }
        }
        .title {
            font-family: $font-load;
            transition: color .3s;
        }
        .author,
        .desc {
            font-size: .875rem;
        }
    }
}

.paper-list {
    article {
        border-bottom: 1px solid $pale;
        padding: calc(5px + 5*(100vw - 320px)/1280) 0;
        font-size: .875rem;
        font-weight: 400;
    }
}

.sec-course {
    .pdf {
        font-size: calc(16px + 4*(100vw - 320px)/1280);
        a {
            transition: color .3s;
            &:before {
                display: inline-block;
                content: url(images/i-pdf.png);
                width: 24px;
                height: 24px;
                margin: 0 10px 0 0;
            }
        }
    }
}

.sec-form {
    background-color: $thin;
    padding: 60px 5%;
    @include media(sm) {
        padding: 60px 5%;
    }
    &--title {
        font-size: calc(18px + 22*(100vw - 320px)/1280);
        text-align: center;
    }
    &--text {
        margin-top: 20px;
        text-align: center;
    }
    &--inner {
        background-color: white;
        padding: calc(20px + 10*(100vw - 320px)/1280) 5% calc(30px + 30*(100vw - 320px)/1280);
        max-width: 960px;
        margin: 40px auto 0;
        @include media(sm) {
            margin: 20px auto 0;
        }
    }
}
.error-list {
    li {
        color: $em;
        text-align: center;
    }
}
.wf-active {
    .sec-form {
        .mailform {
            td {
                input[type="text"],
                textarea,
                select {
                    font-family: $font;
                }
            }
        }
        .mailsend {
            input[type="submit"],
            input[type="reset"] {
                font-family: $font;
            }
        }
    }
}
.sec-form {
    .mailform {
        width: 100%;
        border-collapse: collapse;
        line-height: 1.3;
        @include media(xs) {
            display: block;
        }
    }
    tbody {
        width: 100%;
        @include media(xs) {
            display: block;
        }
    }
    tr {
        width: 100%;
        @include media(xs) {
            display: block;
        }
    }
    th {
        position: relative;
        width: 35%;
        padding: 16px 0 16px 32px;
        font-weight: normal;
        text-align: left;
        vertical-align: middle;
        @include media(xs) {
            display: block;
            width: 100%;
            padding: 10px 0 0 0;
        }
        div {
            position: relative;
        }
        span {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            padding: 4px;
            transform: translate(0, -50%);
            color: $em;
            font-weight: normal;
            line-height: 1em;
            @include media(xs) {
                position: relative;
                display: inline-block;
                transform: translate(0, 0);
                padding: 0 0 0 4px;
            }
        }
    }
    td {
        width: 65%;
        padding: 16px 0 16px 32px;
        vertical-align: middle;
        @include media(xs) {
            display: block;
            width: 100%;
            padding: 10px 0 16px 0;
        }
        &.post {
            input[type="text"] {
                width: 40%;
                margin-right: 10px;
            }
        }
        &.birth {
            select {
            @include media(xs) {
                    padding: 10px 2px;
                }
            }
        }
        input[type="text"],
        textarea,
        select {
            font-family: $font-load;
            background-color: $thin;
        }
        input[type="text"] {
            width: 100%;
            padding: 10px;
            border: none;
        }
        textarea {
            width: 100%;
            padding: 10px;
            min-height: 200px;
            border: none;
        }
        select {
            padding: 10px;
            border: none;
            &:not(:first-child) {
                margin-left: 10px;
            }
        }
    }
    .mailcheck {
        text-align: center;
    }
    .mailsend {
        text-align: center;
        input[type="submit"],
        input[type="reset"] {
            font-family: $font-load;
        }
        input[type="submit"] {
            display: inline-block;
            min-width: 240px;
            padding: 12px 16px;
            background: $main;
            border: 1px solid $main;
            color: white;
            text-align: center;
            transition: .3s;
            &:hover {
                background: white;
                color: $main;
            }
        }
        input[type="reset"] {
            display: inline-block;
            min-width: 160px;
            padding: 8px 12px;
            background: $pale;
            border: 1px solid $pale;
            color: $black;
            text-align: center;
            transition: .3s;
            margin-top: 20px;
            &:hover {
                background: white;
            }
        }
    }
    .mailback {
        button {
            display: inline-block;
            padding: 8px 12px;
            min-width: 160px;
            position: relative;
            border: 1px solid $aux;
            background-color: $aux;
            color: white;
            transition: color .3s, background .3s;
            text-align: center;
            line-height: 1.3;
            cursor: pointer;
            &:hover {
                border: 1px solid $aux;
                background-color: white;
                color: $aux;
            }
        }
    }
}

.sec-map {
    background-color: $thin;
}

.sec-story {
    .photo-area {
        margin: 0 auto 20px;
        width: 220px;
        @include media(sm) {
            float: left;
            margin: 0 20px 20px 0;
        }
        figure {
            background-color: $thin;
            padding: 10px;
        }
        figcaption {
            line-height: 1.5;
        }
    }
    p {
        text-indent: 1rem;
    }
}

























// ----------------------------------------
// メニュー
// ----------------------------------------



// ----------------------------------------
// フッター
// ----------------------------------------

.footer {
    padding: 80px 0 0;
    background-color: $thin;
    @include media(md) {
        padding: 120px 0 0;
        position: relative;
    }
    &-wrap {
        padding: 0;
        background-color: $kthin;
    }
    &-inner {
        padding: 60px 5% 0;
        @include media(md) {
            margin: 0 auto;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
    }
    &-info {
        line-height: 1.4;
        @include media(md) {
            width: 50%;
        }
        &--title {
            font-size: 0.875rem;
            span {
                display: block;
                font-size: calc(20px + 10*(100vw - 320px)/1280);
                font-weight: 700;
            }
        }
        &--address {
            font-style: normal;
            margin-top: 10px;
            span {
                display: block;
                font-size: 0.875rem;
            }
        }
        &--tel {
            font-size: 20px;
            line-height: 1.3;
            letter-spacing: 2px;
            font-weight: 700;
            color: $black;
            span {
                display: block;
                font-size: calc(14px + 4*(100vw - 320px)/1280);
                letter-spacing: 0;
                font-weight: 400;
            }
            a {
                color: $black;
                text-decoration: none;
            }
        }
        &--mail {
            a {
                color: $black;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    &-time {
        margin: 20px 0 0;
        @include media(md) {
            margin: 0;
            width: 50%;
        }
        .table-simple {
            max-width: 520px;
            margin: 0 auto;
            border-top: 1px solid $black;
            @include media(md) {
                margin: 0 0 0 auto;
            }
            thead {
                background-color: inherit;
            }
            th, td {
                border-bottom: 1px solid $black;
                padding: 5px 0;
                font-size: 0.875rem;
                font-weight: 700;
                line-height: 1.3;
                @include media(sm) {
                    font-size: 1rem;
                }
                span {
                    display: block;
                    font-size: 1rem;
                    @include media(sm) {
                        display: inline;
                        font-size: 1.25rem;
                    }
                }
            }
            td {
                width: calc(30px + 20*(100vw - 320px)/1280);
                &.wide {
                    width: 60px;
                }
            }
        }
    }
    &-copyright {
        text-align: center;
        font-size: 0.75rem;
        margin-top: 20px;
        @include media(md) {
            text-align: right;
        }
    }
    &-totop {
        position: relative;
        width: 100%;
        margin: 60px auto 0;
        padding: 30px 0;
        display: block;
        background-color: $key;
        &:hover {
            background-color: darken($key, 10%);
        }
        &:before {
            position: absolute;
            display: block;
            content: '';
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            border-top: 3px solid white;
            border-right: 3px solid white;
            transform: rotate(-45deg) translate(-50%, -50%);
        }
    }
}

// ----------------------------------------
// 特殊
// ----------------------------------------


// grobal nav
.is-animation #gnav {
    display: inline-block;
}
.gnav--burger {
    position: fixed;
    top: 40px;
    right: 20px;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    z-index: 1000;
    display: block;
    @include media(md) {
        top: 30px;
        right: 30px;
        display: none;
    }
    &.active {
        div {
            &:nth-of-type(2) {
                opacity: 0;
            }
        }
    }
    &-icon {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 16px;
        div {
            position: absolute;
            display: inline-block;
            transition: transform .3s;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $black;
            &:nth-of-type(1) {
                top: 0;
                -webkit-animation: burger-bar01 .75s forwards;
                animation: burger-bar01 .75s forwards;
            }
            &:nth-of-type(2) {
                top: 7px;
                opacity: 1;
            }
            &:nth-of-type(3) {
                bottom: 0;
                -webkit-animation: burger-bar03 .75s forwards;
                animation: burger-bar03 .75s forwards;
            }
        }
    }
    &-text {
        display: block;
        font-size: 0.75rem;
        color: $black;
        line-height: 1;
        font-weight: 400;
    }
}
@-webkit-keyframes burger-bar01 {
    0% { -webkit-transform: translateY(7px) rotate(45deg); }
    50% { -webkit-transform: translateY(7px) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(0); }
}
@keyframes burger-bar01 {
    0% { transform: translateY(7px) rotate(45deg); }
    50% { transform: translateY(7px) rotate(0); }
    100% { transform: translateY(0) rotate(0); }
}
@-webkit-keyframes burger-bar03 {
    0% { -webkit-transform: translateY(-7px) rotate(-45deg); }
    50% { -webkit-transform: translateY(-7px) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(0); }
}
@keyframes burger-bar03 {
    0% { transform: translateY(-7px) rotate(-45deg); }
    50% { transform: translateY(-7px) rotate(0); }
    100% { transform: translateY(0) rotate(0); }
}


// drawer
.scroll-prevent {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.drawermenu {
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    width: 85vw;
    height: 100%;
    overflow: auto;
    background-color: $thin;
    transition: opacity .3s ease-out;
    padding: 30px calc(20px + 20*(100vw - 320px)/1280) 30px calc(20px + 20*(100vw - 320px)/1280);
    opacity: 0;
    -webkit-overflow-scrolling: touch;
    text-align: right;
    animation: drawermenu 1s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
    @include media(sm) {
        width: 60vw;
    }
    @include media(md) {
        width: 40vw;
    }
    &.hide {
        opacity: 0;
    }
    &.is-active {
        opacity: 1;
        z-index: 2000;
    }
    .drawermenu--burger-icon {
        cursor: pointer;
        position: relative;
        display: inline-block;
        width: 30px;
        height: 30px;
        div {
            position: absolute;
            display: inline-block;
            transition: transform .3s;
            left: 0;
            width: 20px;
            height: 2px;
            background-color: $black;
            &:nth-of-type(1) {
                top: 50%;
                transform: rotate(45deg) translate(-50%, -50%);
                -webkit-animation: drawer-bar01 .75s forwards;
                animation: drawer-bar01 .75s forwards;
            }
            &:nth-of-type(2) {
                opacity: 0;
            }
            &:nth-of-type(3) {
                top: 50%;
                transform: rotate(-45deg) translate(-50%, -50%);
                -webkit-animation: drawer-bar03 .75s forwards;
                animation: drawer-bar03 .75s forwards;
            }
        }
    }
}
@-webkit-keyframes drawer-bar01 {
    0% { -webkit-transform: translateY(0) rotate(0); }
    50% { -webkit-transform: translateY(0) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(45deg); }
}
@keyframes drawer-bar01 {
    0% { transform: translateY(0) rotate(0); }
    50% { transform: translateY(0) rotate(0); }
    100% { transform: translateY(0) rotate(45deg); }
}
@-webkit-keyframes drawer-bar03 {
    0% { -webkit-transform: translateY(0) rotate(0); }
    50% { -webkit-transform: translateY(0) rotate(0); }
    100% { -webkit-transform: translateY(0) rotate(-45deg); }
}
@keyframes drawer-bar03 {
    0% { transform: translateY(0) rotate(0); }
    50% { transform: translateY(0) rotate(0); }
    100% { transform: translateY(0) rotate(-45deg); }
}


.drawer-nav--section .tel {
    font-family: $font-en;
}


.drawer-nav {
    &--section {
        border-top: 1px solid $pale;
        padding-top: 20px;
        text-align: left;
        &-first {
            border-top: none;
            padding-top: 0;
        }
        .address {
            font-size: 0.875rem;
            line-height: 1.4;
            color: $aux;
            span {
                font-size: 0.75rem;
                display: block;
                color: $aux;
            }
        }
        .tel {
            font-size: calc(20px + 10*(100vw - 320px)/1280);
            font-weight: 700;
            line-height: 1.3;
            a {
                color: $link;
            }
            span {
                display: block;
                font-size: 0.875rem;
                font-weight: 500;
            }
        }
    }
    &--title {
        font-size: calc(14px + 4*(100vw - 320px)/1280);
        margin-bottom: 10px;
        a {
            color: $black;
            transition: color .3s ease-in-out;
            &:hover {
                color: $link;
            }
        }
    }
    &--list {}
    &--item {
        line-height: 1.4;
        &:not(:first-child){
            margin-top: 10px;
        }
        a {
            color: $black;
            font-size: calc(14px + 4*(100vw - 320px)/1280);
            transition: color .3s ease-in-out;
            &:hover {
                color: $link;
            }
            svg {
                height: 12px;
                width: auto;
                margin: 0 0 0 5px;
            }
        }
        .toggle {
            cursor: pointer;
            &:hover {
                span {
                    color: $link;
                    &:after {
                        border-top: 4px solid $link;
                    }
                }
            }
            span {
                display: inline-block;
                font-size: calc(14px + 4*(100vw - 320px)/1280);
                line-height: 1.3;
                color: $black;
                transition: color .3s ease-in-out;
                &:after {
                    display: inline-block;
                    content: '';
                    width: 4px;
                    height: 4px;
                    border: 4px solid transparent;
                    border-top: 4px solid $black;
                    margin-left: 5px;
                    vertical-align: middle;
                    transition: border-top .3s ease-in-out;
                }
            }
            &.active {
                span {
                    color: $link;
                    &:after {
                        border-bottom: 4px solid $link;
                        border-top: none;
                    }
                }
            }
        }
        .child {
            padding-left: calc(10px + 20*(100vw - 320px)/1280);
            margin: 0 0 10px;
            line-height: 1.3;
            &.hide {
                display: none;
            }
            &--item {
                margin-top: 10px;
            }
        }
    }
}


.page-cover {
    display: none;
    &.is-active {
        display: block;
        z-index: 1900;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.6);
    }
}


// contentbottom
.sec-contentbottom {
    margin-top: calc(80px + 80*(100vw - 320px)/1280);
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    @include media(md) {
        max-width: 60%;
    }
    &--title {
        font-size: calc(18px + 12*(100vw - 320px)/1280);
        text-align: center;
    }
    .swiper-contentbottom {
        padding: 0;
    }
    .swiper-slide {
        margin: 0;
    }
    .swiper-pagination-contentbottom {
        text-align: center;
        .swiper-pagination-bullet {
            width: 20px;
            height: 2px;
            border-radius: 0;
        }
        .swiper-pagination-bullet-active {
            background: $key;
        }
    }
}

.card-contentbottom {
    .caption {
        margin-top: calc(10px + 10*(100vw - 320px)/1280);
        .title {
            font-size: calc(14px + 4*(100vw - 320px)/1280);
            line-height: 1.3;
            font-weight: normal;
            color: $black;
            span {
                font-weight: bold;
                display: block;
            }
        }
    }
}

// slick

.slick-dots {
    bottom: 10vh;
    padding: 0 10%;
    display: flex;
    justify-content: space-between;
    li {
        width: 40%;
        height: 6px;
        @include media(md) {
            height: 3px;
        }
        &.slick-active {
            background-color: #fff;
            button:before {
                opacity: 1;
                left: 0;
                -webkit-animation: slick-bar 5s linear;
                animation: slick-bar 5s linear;
                background-color: $key;
            }
        }
        button:before {
            content: '';
            background-color: $black;
            opacity: 0.4;
            width: 100%;
            height: 6px;
            @include media(md) {
                height: 3px;
            }
        }
    }
}
@-webkit-keyframes slick-bar {
    0% { width: 0; }
    100% { width: 100%; }
}
@keyframes slick-bar {
    0% { width: 0; }
    100% { width: 100%; }
}

.slider:hover {
    .slick-next {
        right: 3%;
    }
    .slick-prev {
        left: 3%;
    }
}
.slick-next {
    right: -50px;
    transition: right .3s ease-in-out;
    &:hover:before {
        opacity: 1;
    }
}
.slick-prev {
    left: -50px;
    transition: left .3s ease-in-out;
    z-index: 1;
    &:hover:before {
        opacity: 1;
    }
}
.slick-prev:before,
.slick-next:before {
    font-size: calc(20px + 20*(100vw - 320px)/1280);
    opacity: .6;
    transition: opacity .3s ease-out;
    text-shadow: $aux 0 0 10px;
}


// swiper
.swiper-container {
    padding: 0 5%;
    @include media(md) {
        padding: 0 10%;
    }
    &.swiper-hero {
        padding: 0;
        .card {
            min-height: 100vh;
            max-height: 100vh;
        }
    }
    .swiper-slide {
        flex: 0 0 auto;
        margin: 0 auto;
        &.card-title {
            min-width: 90%;
            max-width: 90%;
            width: 90%;
            @include media(md) {
                min-width: 70%;
                max-width: 70%;
                width: 70%;
            }
            @include media(lg) {
                min-width: 45%;
                max-width: 45%;
                width: 45%;
            }
        }
        &.card-lg {
            min-width: 90%;
            max-width: 90%;
            width: 90%;
        }
        &.card-md {
            min-width: 90%;
            max-width: 90%;
            width: 90%;
            @include media(md) {
                min-width: 50%;
                max-width: 50%;
                width: 50%;
            }
        }
        &.card-sm {
            min-width: 80%;
            max-width: 80%;
            width: 80%;
            @include media(sm) {
                min-width: 60%;
                max-width: 60%;
                width: 60%;
            }
            @include media(md) {
                min-width: 50%;
                max-width: 50%;
                width: 50%;
            }
            @include media(lg) {
                min-width: 30%;
                max-width: 30%;
                width: 30%;
            }
        }
    }
    a.card {
        &:hover {
            .pic {
                transform: scale(1.05);
            }
        }
    }
    .card {
        .pic-outer {
            overflow: hidden;
            position: relative;
            display: block;
            padding-top: 61.8%;
            content: '';
        }
        .pic {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            transition: transform .3s, filter .3s;
        }
        .caption-title {
            position: absolute;
            left: 5vw;
            bottom: 10%;
            .title {
                color: white;
                font-size: calc(18px + 22*(100vw - 320px)/1280);
                line-height: 1.3;
                text-shadow: $black 0 0 10px;
                font-weight: 500;
            }
            .text {
                color: white;
                font-size: calc(14px + 4*(100vw - 320px)/1280);
                line-height: 1.4;
                margin-top: 10px;
                text-shadow: $black 0 0 10px;
            }
            .button {
                margin-top: 20px;
            }
        }
        .caption-body {
            padding: 30px 8%;
            .title {
                color: $black;
                font-size: 20px;
                line-height: 1.3;
            }
            .text {
                color: $black;
                font-size: calc(14px + 2*(100vw - 320px)/1280);
                margin-top: 10px;
            }
        }
        .caption-headline-item {
            padding: 20px 5px 0;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            .title {
                font-weight: normal;
                color: white;
                font-size: calc(18px + 4*(100vw - 320px)/1280);
                line-height: 1.3;
                text-shadow: $black 0 0 5px;
            }
        }
        .caption-number {
            padding-right: 5%;
            width: 100%;
            hgroup {
                display: flex;
                flex-direction: column;
            }
            .number {
                font-size: 2.5rem;
                font-weight: 400;
                line-height: 1.3;
                padding-bottom: 40px;
            }
            .title {
                font-size: calc(16px + 22*(100vw - 320px)/1280);
                font-weight: 400;
                line-height: 1.3;
                padding-bottom: 40px;
            }
            .text {
                text-align: justify;
            }
        }
        .caption-category {
            hgroup {
                display: flex;
                flex-direction: column;
            }
            .title {
                font-size: 0.875rem;
                line-height: 1.3;
                padding-bottom: 60px;
            }
            .detail {
                font-size: 1.875rem;
                line-height: 1.3;
                padding-bottom: 60px;
            }
        }
        .caption-text {
            padding: 0 5%;
        }
        .caption-desc {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: auto;
            background-color: white;
            border-bottom: 1px solid $pale;
            padding: 10px 20px;
            .text {
                font-size: calc(14px + 4*(100vw - 320px)/1280);
                line-height: 1.3;
            }
        }
    }
    &:hover {
        .swiper-button-prev {
            left: 5%;
        }
        .swiper-button-next {
            right: 5%;
        }
    }
}

// swiper
.swiper-container2 {
    padding: 0 5%;
    @include media(md) {
        padding: 0 10%;
    }
    .swiper-slide {
        flex: 0 0 auto;
        margin: 0 auto;
        &.card-title {
            min-width: 90%;
            max-width: 90%;
            width: 90%;
            @include media(md) {
                min-width: 70%;
                max-width: 70%;
                width: 70%;
            }
            @include media(lg) {
                min-width: 45%;
                max-width: 45%;
                width: 45%;
            }
        }
        &.card-lg {
            min-width: 90%;
            max-width: 90%;
            width: 90%;
        }
        &.card-md {
            min-width: 90%;
            max-width: 90%;
            width: 90%;
            @include media(md) {
                min-width: 50%;
                max-width: 50%;
                width: 50%;
            }
        }
        &.card-sm {
            min-width: 80%;
            max-width: 80%;
            width: 80%;
            @include media(sm) {
                min-width: 60%;
                max-width: 60%;
                width: 60%;
            }
            @include media(md) {
                min-width: 50%;
                max-width: 50%;
                width: 50%;
            }
            @include media(lg) {
                min-width: 30%;
                max-width: 30%;
                width: 30%;
            }
            .card {
                min-height: 30vh;
                max-height: 30vh;
                height: 30vh;
            }
        }
    }
    a.card {
        &:hover {
            .pic {
                transform: scale(1.05);
            }
        }
    }
    .card {
        display: flex;
        align-items: center;
        position: relative;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        overflow: hidden;
        min-height: 65vh;
        max-height: 65vh;
        height: 65vh;
        @include media(sm) {
            min-height: 55vh;
            max-height: 55vh;
            height: 55vh;
        }
        @include media(md) {
            min-height: 40vh;
            max-height: 40vh;
            height: 40vh;
        }
        @include media(xl) {
            min-height: 55vh;
            max-height: 55vh;
            height: 55vh;
        }
        &:before {
            display: block;
            padding-top: 100%;
            content: '';
        }
        .pic {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            transition: transform .3s;
        }
        .caption-title {
            position: absolute;
            left: 5vw;
            bottom: 10%;
            .title {
                color: white;
                font-size: calc(18px + 22*(100vw - 320px)/1280);
                line-height: 1.3;
                text-shadow: $black 0 0 10px;
                font-weight: 500;
            }
            .text {
                color: white;
                font-size: calc(14px + 4*(100vw - 320px)/1280);
                line-height: 1.4;
                margin-top: 10px;
                text-shadow: $black 0 0 10px;
            }
            .button {
                margin-top: 20px;

            }
        }
        .caption-headline-item {
            padding: 20px 5px 0;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            .title {
                font-weight: normal;
                color: white;
                font-size: calc(18px + 4*(100vw - 320px)/1280);
                line-height: 1.3;
                text-shadow: $black 0 0 5px;
            }
        }
        .caption-number {
            padding-right: 5%;
            width: 100%;
            &.pd-lg {
                padding: 0 10%;
            }
            hgroup {
                display: flex;
                flex-direction: column;
            }
            .number {
                font-size: 4.0rem;
                font-weight: 400;
                line-height: 1.3;
                padding-bottom: 40px;
            }
            .title {
                font-size: calc(20px + 20*(100vw - 320px)/1280);
                font-weight: 700;
                line-height: 1.3;
                padding-bottom: calc(20px + 20*(100vw - 320px)/1280);
            }
            .text {
                text-align: justify;
            }
        }
        .caption-category {
            hgroup {
                display: flex;
                flex-direction: column;
            }
            .title {
                font-size: 1.4rem;
                line-height: 1.3;
                padding-bottom: 60px;
            }
            .detail {
                font-size: 3.0rem;
                line-height: 1.3;
                padding-bottom: 60px;
            }
        }
        .caption-text {
            padding: 0 5%;
            &.pd-lg {
                padding: 0 10%;
            }
        }
        .caption-desc {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: auto;
            background-color: white;
            border-bottom: 1px solid $pale;
            padding: 10px 20px;
            .text {
                font-size: calc(14px + 4*(100vw - 320px)/1280);
                line-height: 1.3;
            }
        }
    }
    &:hover {
        .swiper-button-prev {
            left: 5%;
        }
        .swiper-button-next {
            right: 5%;
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    width: calc(20px + 20*(100vw - 320px)/1280);
    height: calc(20px + 20*(100vw - 320px)/1280);
    margin-top: -5%;
    transition: left .3s ease-in-out, right .3s ease-in-out;
    opacity: .6;
    filter: drop-shadow(0 0 10px $aux);
    &:hover {
        opacity: 1;
    }
}
.swiper-button-prev {
    left: -20%;
    background: url(images/i-arrow-left.svg) no-repeat center center / cover;
    &.swiper-button-disabled {
        display: none;
    }
}
.swiper-button-next {
    right: -20%;
    background: url(images/i-arrow-right.svg) no-repeat center center / cover;
    &.swiper-button-disabled {
        display: none;
    }
}
.swiper-container-horizontal {
    & > .swiper-scrollbar {
        bottom: 0;
        border-radius: 0;
        width: 90%;
        left: 5%;
        position: relative;
        padding: calc(20px + 10*(100vw - 320px)/1280) 0;
        height: 2px;
        background: transparent 50%/2px url(images/bg-swiper-scrollbar.png) repeat-x !important;
        &:hover {
            .swiper-scrollbar-drag {
                &:after {
                    height: 6px;
                    top: -3px;
                    border-radius: 10px;
                }
            }
        }
        .swiper-scrollbar-drag {
            background-color: lighten($main, 20%);
            border-radius: 0;
            &:after {
                background: lighten($main, 20%);
                content: "";
                height: 2px;
                left: 0;
                position: absolute;
                right: 0;
                top: -1px;
                transition: height .2s ease-out, top .2s ease-in-out, border-radius .2s ease-in-out;
            }
        }
    }
}


/*
.loadingEffect {
    display: block;
    z-index: 100000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

.loadingEffect::before, .loadingEffect::after {
    display: block;
    z-index: 99;
    position: fixed;
    right: 0;
    left: 0;
    width: 100%;
    height: 50%;
    margin-right: auto;
    margin-left: auto;
    background-color: #F5F5F5;
    line-height: 1;
    content: ' ';
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
    transition-delay: .3s;
}

.loadingEffect::before {
    top: 0;
}

.loadingEffect::after {
    bottom: 0;
}

.loaded .loadingEffect::before {
    height: 0;
    transform: translateY(-1%);
}

.loaded .loadingEffect::after {
    height: 0;
    transform: translateY(1%);
}
*/


